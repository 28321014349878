export const SERVICES_HASH = 'our-services'

export const SERVICES: Link = {
  href: `/?service=implementation#${SERVICES_HASH}`,
  pathname: '/',
  hash: `${SERVICES_HASH}`,
  // query: {
  //   service: 'implementation',
  // },
}

export const BLOGS: Link = {
  pathname: '/',
  hash: 'blogs',
}

export const GET_QUOTATION: Link = {
  pathname: '/',
  hash: 'get-quotation',
}
