import { getCookie } from '@/lib/utils/cookies'
import { COOKIE_LOCALE } from './constants'
import { Language } from './types'

export function getCurrentLanguage(): Language | null {
  return getCookie<Language>(COOKIE_LOCALE) as Language
}

export function getCurrentOption<T = unknown>(
  options: (T & { value: string })[],
  langauge: string,
): T | null {
  const currentOption = options.find((option) => {
    return option.value === langauge
  })

  if (currentOption) return currentOption

  return null
}
