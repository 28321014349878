import { Fragment } from 'react'
import {
  Box,
  Icon,
  Divider,
  Popover,
  PopoverTrigger,
  PopoverBody,
  useDisclosure,
  CSSObject,
} from '@chakra-ui/react'
import { FiChevronDown } from 'react-icons/fi'
import { useSpring, config } from 'react-spring'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'

import { AnimatedPopoverContent } from '@/lib/springComponent'

import { getCurrentOption } from '@/lib/localization/helpers'
import { Language } from '@/lib/localization/types'

import NavigationButton from './NavigationButton'
import { LANGUAGE_SELECT_OPTION } from './constants'

export const NAVIGATION_BAR_HEIGHT = {
  base: '60px',
  md: '72px',
}

interface Props {
  sx?: CSSObject
}

function LanguageSelect({ sx }: Props): React.ReactElement {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const {
    i18n: { language },
  } = useTranslation()

  const router = useRouter()

  const setLanguage = (language: Language) => {
    let hash: string | undefined = undefined

    const splitedUrl = router.asPath.split('#')

    if (splitedUrl.length >= 2) {
      hash = splitedUrl[splitedUrl.length - 1]
    }

    router.replace(
      { pathname: router.pathname, query: router.query, hash: hash },
      undefined,
      {
        locale: language,
      },
    )
  }

  const spring = useSpring({
    from: {
      opacity: 0,
      transform: 'translate3d(0, 8px, 0)',
    },
    to: {
      opacity: isOpen ? 1 : 0,
      transform: isOpen ? 'translate3d(0, 0px, 0)' : 'translate3d(0, 8px, 0)',
    },
    config: config.stiff,
  })

  const currentOption =
    getCurrentOption(LANGUAGE_SELECT_OPTION, language) ??
    LANGUAGE_SELECT_OPTION[0]

  return (
    <Popover
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      placement="bottom"
      trigger="hover"
      isLazy
      lazyBehavior="keepMounted"
      matchWidth
    >
      <PopoverTrigger>
        <Box>
          <NavigationButton
            {...currentOption}
            rightIcon={<Icon as={FiChevronDown} w="3" />}
            sx={{
              textTransform: 'uppercase',
              '& > *:last-of-type': { px: 4 },
              fontSize: 'sm',
              ...sx,
            }}
          />
        </Box>
      </PopoverTrigger>
      <AnimatedPopoverContent
        style={{
          opacity: spring.opacity,
          transform: spring.transform,
          visibility: spring.opacity.to((opacity) =>
            opacity > 0.1 ? 'visible' : 'hidden',
          ),
        }}
        sx={{
          w: '',
          boxShadow: 'none !important',
          border: 'none',
          backgroundColor: 'transparent',
          display: 'flex',
          justifyContent: 'center',
          alignItems: { base: 'stretch', md: 'flex-start' },
          transition: isOpen ? 'none' : 'visibility 0ms 150ms',
          visibility: isOpen ? 'visible' : 'hidden !important',
        }}
      >
        <PopoverBody
          sx={{
            m: 0,
            p: 0,
            borderRadius: 'md',
            borderColor: 'gray.300',
            backgroundColor: 'white',
            overflow: 'hidden',
            boxShadow: 'md',
          }}
        >
          {LANGUAGE_SELECT_OPTION.filter(
            ({ value }) => value !== currentOption.value,
          ).map(({ divided, ...menu }, index) => (
            <Fragment key={`${index}`}>
              <Box
                sx={{
                  h: '40px',
                }}
              >
                <NavigationButton
                  {...menu}
                  onClick={() => {
                    setLanguage(menu.value)
                  }}
                  sx={{
                    fontSize: 'sm',
                    '& > *:last-of-type': {
                      justifyContent: 'flex-start',
                      px: 4,
                    },
                  }}
                />
              </Box>
              {divided && <Divider sx={{ borderColor: 'inherit' }} />}
            </Fragment>
          ))}
        </PopoverBody>
      </AnimatedPopoverContent>
    </Popover>
  )
}

export default LanguageSelect
