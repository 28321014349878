import { Flex } from '@chakra-ui/react'

import MenuDesktopButton from './MenuDesktopButton'

import { useLinks } from './constants'

export const NAVIGATION_BAR_HEIGHT = {
  base: '60px',
  md: '72px',
}

function MenuDesktop(): React.ReactElement {
  const LINKS = useLinks()

  return (
    <Flex
      sx={{
        display: {
          base: 'none',
          md: 'flex',
        },
        h: 'full',
        flexFlow: 'row nowrap',
      }}
    >
      {LINKS.map((link, index) => {
        return <MenuDesktopButton key={`menu-${index}`} {...link} />
      })}
    </Flex>
  )
}

export default MenuDesktop
