import { Box, Text, Spacer, Stack } from '@chakra-ui/react'

import { LinksSmall, LinksMedium } from './components'
import { useLinks } from './constants'

function Footer(): React.ReactElement {
  const LINKS = useLinks()

  return (
    <Box
      id="footer"
      as="section"
      sx={{
        w: 'full',
        fontSize: 'sm',
        minH: 14,
      }}
    >
      <LinksSmall links={LINKS} />
      <Box
        sx={{
          w: 'full',
          boxShadow: 'inset 0px 1px 0px #E8E8E8',
          py: 4,
        }}
      >
        <Stack
          layerStyle="container"
          spacing={{
            base: 2,
            md: 7,
          }}
          direction={{
            base: 'column',
            sm: 'row',
          }}
          sx={{
            alignItems: 'center',
            pt: 0,
            pb: 0,
            color: 'gray.600',
          }}
        >
          <Text align="center">
            ©{new Date().getFullYear()}{' '}
            <Text as="strong" sx={{ fontWeight: 'bold' }}>
              Data Wow Co., Ltd.
            </Text>{' '}
            All Rights Reserved.
          </Text>
          <Spacer />
          <LinksMedium links={LINKS} />
          <Text align="center">Made with ♥ in Bangkok</Text>
        </Stack>
      </Box>
    </Box>
  )
}

export default Footer
