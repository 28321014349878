import { SELF_ASSESSMENT_LOCALES } from '@/lib/localization/constants'

export const RESOURCES: Link = {
  pathname: '/resources',
  hash: '',
}

export const HANDOUT_FORM: Link = {
  pathname: '/resources/handout-and-self-checklist',
  hash: 'get-resource',
}

export const GET_HANDOUT: Link = {
  pathname: '/resources/handout-and-self-checklist/download',
  hash: '',
}

export const COMPLIANCE_FORM: Link = {
  pathname: '/resources/pdpa-compliance-guideline',
  hash: 'get-resource',
}

export const GET_COMPLIANCE: Link = {
  pathname: '/resources/pdpa-compliance-guideline/download',
  hash: '',
  locales: SELF_ASSESSMENT_LOCALES,
}

export const RISK_ASSESSMENT: Link = {
  pathname: '/resources/self-risk-assessment',
  hash: '',
  locales: SELF_ASSESSMENT_LOCALES,
}

export const DATASHEET_FORM: Link = {
  pathname: '/resources/pdpa-implementation-datasheet',
  hash: 'get-resource',
  locales: SELF_ASSESSMENT_LOCALES,
}

export const GET_DATASHEET: Link = {
  pathname: '/resources/pdpa-implementation-datasheet/download',
  hash: '',
  locales: SELF_ASSESSMENT_LOCALES,
}

export const TRAINING_FORM: Link = {
  pathname: '/resources/pdpa-awareness-training-course-outline-datasheet',
  hash: 'get-resource',
}

export const LEGAL_FORM: Link = {
  pathname: '/resources/pdpa-legal-document-datasheet',
  hash: 'get-resource',
}

export const GET_TRAINING: Link = {
  pathname:
    '/resources/pdpa-awareness-training-course-outline-datasheet/download',
  hash: '',
}
export const GET_LEGAL: Link = {
  pathname: '/resources/pdpa-legal-document-datasheet/download',
  hash: '',
}
