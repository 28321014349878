import { useMemo } from 'react'
import { Icon } from '@chakra-ui/react'
import Router from 'next/router'
import { FaPhoneAlt } from 'react-icons/fa'
import { useTranslation } from 'next-i18next'

import * as URLs from '@/routes/landing'
import { BLOGS, ABOUT_US, HOME, OTHER_SERVICES } from '@/routes'
import { FACEBOOK, TEL } from '@/routes/external'
import {
  IMPLEMENTATION,
  ADVISORY,
  TRAINING,
  COMPLIANCE,
  LEGAL,
} from '@/routes/services'
import {
  RESOURCES,
  HANDOUT_FORM,
  COMPLIANCE_FORM,
  RISK_ASSESSMENT,
  DATASHEET_FORM,
  TRAINING_FORM,
  LEGAL_FORM,
} from '@/routes/resources'

import { SELF_ASSESSMENT_LOCALES } from '@/lib/localization/constants'

import COLORS from '@/styles/styles/colors'

import { logEvent } from '@/lib/gtm'
import { BLOG_LOCALES } from '@/lib/localization/constants'
import { LanguageSelectOption } from '@/lib/localization/types'

import { ReactComponent as Facebook } from '@/svg/logo-facebook.svg'

import { ReactComponent as GBFlag } from '@/svg/flags/gb.svg'
import { ReactComponent as THFlag } from '@/svg/flags/th.svg'
import { ReactComponent as JPFlag } from '@/svg/flags/jp.svg'

export type NavLink = {
  label: React.ReactNode
  leftIcon?: React.ReactNode
  rightIcon?: React.ReactNode
  getLink?: () => Link
  onClick?: () => void
  color?: string
  backgroundColor?: string
  divided?: boolean
  subMenus?: NavLink[]
}

export const useLinks = (): NavLink[] => {
  const {
    t,
    i18n: { language },
  } = useTranslation('common')

  return useMemo(
    () => [
      {
        label: t('navigation.services'),
        getLink: (): Link => URLs.SERVICES,
        backgroundColor: '#ffffff',
        divided: true,
        subMenus: [
          {
            label: t('navigation.implementation'),
            getLink: (): Link => IMPLEMENTATION,
            backgroundColor: '#ffffff',
            divided: true,
            onClick: (): void => {
              logEvent({
                ga: {
                  category: 'Landing Page',
                  action: 'Click implementation',
                },
              })
            },
          },
          {
            label: t('navigation.advisory'),
            getLink: (): Link => ADVISORY,
            backgroundColor: '#ffffff',
            divided: true,
            onClick: (): void => {
              logEvent({
                ga: {
                  category: 'Landing Page',
                  action: 'Click advisory',
                },
              })
            },
          },
          {
            label: t('navigation.training'),
            getLink: (): Link => TRAINING,
            backgroundColor: '#ffffff',
            divided: true,
            onClick: (): void => {
              logEvent({
                ga: {
                  category: 'Landing Page',
                  action: 'Click training',
                },
              })
            },
          },
          {
            label: t('navigation.compliance'),
            getLink: (): Link => COMPLIANCE,
            backgroundColor: '#ffffff',
            divided: true,
            onClick: (): void => {
              logEvent({
                ga: {
                  category: 'Landing Page',
                  action: 'Click compliance',
                },
              })
            },
          },
          {
            label: t('navigation.legal'),
            getLink: (): Link => LEGAL,
            backgroundColor: '#ffffff',
            onClick: (): void => {
              logEvent({
                ga: {
                  category: 'Landing Page',
                  action: 'Click legal',
                },
              })
            },
          },
        ],
      },
      {
        label: t('navigation.resources'),
        getLink: (): Link => ({ ...RESOURCES, hash: '' }),
        backgroundColor: '#ffffff',
        divided: true,
        onClick: (): void => {
          logEvent({
            ga: {
              category: 'Landing Page',
              action: 'Click resources',
            },
          })
        },
        subMenus: [
          {
            label: t('navigation.handout'),
            getLink: (): Link => HANDOUT_FORM,
            backgroundColor: '#ffffff',
            divided: true,
            onClick: (): void => {
              logEvent({
                ga: {
                  category: 'Landing Page',
                  action: 'Click PDPA Core handout',
                },
              })
            },
          },
          {
            label: t('navigation.complianceGuildLine'),
            getLink: (): Link => COMPLIANCE_FORM,
            backgroundColor: '#ffffff',
            divided: SELF_ASSESSMENT_LOCALES.includes(language),
            onClick: (): void => {
              logEvent({
                ga: {
                  category: 'Landing Page',
                  action: 'Click compliance Guideline',
                },
              })
            },
          },
          ...(SELF_ASSESSMENT_LOCALES.includes(language)
            ? [
                {
                  label: t('navigation.riskAssessment'),
                  getLink: (): Link => RISK_ASSESSMENT,
                  backgroundColor: '#ffffff',
                  divided: true,
                  onClick: (): void => {
                    logEvent({
                      ga: {
                        category: 'Landing Page',
                        action: 'Click riskAssessment',
                      },
                    })
                  },
                },
                {
                  label: t('navigation.datasheet'),
                  getLink: (): Link => DATASHEET_FORM,
                  backgroundColor: '#ffffff',
                  onClick: (): void => {
                    logEvent({
                      ga: {
                        category: 'Landing Page',
                        action: 'Click datasheet',
                      },
                    })
                  },
                },
              ]
            : []),
          {
            label: t('navigation.trainingCourseOutline'),
            getLink: (): Link => TRAINING_FORM,
            backgroundColor: '#ffffff',
            onClick: (): void => {
              logEvent({
                ga: {
                  category: 'Landing Page',
                  action: 'Click trainingCourseOutline',
                },
              })
            },
          },
          {
            label: t('navigation.legalDocument'),
            getLink: (): Link => LEGAL_FORM,
            backgroundColor: '#ffffff',
            onClick: (): void => {
              logEvent({
                ga: {
                  category: 'Landing Page',
                  action: 'Click legalDocument',
                },
              })
            },
          },
        ],
      },
      {
        label: t('navigation.aboutUs'),
        getLink: (): Link => ABOUT_US,
        divided: true,
        backgroundColor: '#ffffff',
        onClick: (): void => {
          logEvent({
            ga: {
              category: 'About us',
              action: 'Click about us',
            },
          })
        },
      },
      {
        label: t('navigation.otherServices'),
        getLink: (): Link => OTHER_SERVICES,
        divided: true,
        backgroundColor: '#ffffff',
        onClick: (): void => {
          logEvent({
            ga: {
              category: 'Other Ser',
              action: 'Click other services',
            },
          })
        },
      },
      ...(BLOG_LOCALES.includes(language)
        ? [
            {
              label: t('navigation.blogs'),
              getLink: (): Link => BLOGS,
              backgroundColor: '#ffffff',
              onClick: (): void => {
                logEvent({
                  ga: {
                    category: 'Blog',
                    action: 'Click Blog',
                  },
                })
              },
            },
          ]
        : []),
      {
        label: t('navigation.getQuotation'),
        getLink: (): Link => {
          if (global.window) {
            if (
              [
                HOME.pathname,
                ABOUT_US.pathname,
                IMPLEMENTATION.pathname,
                ADVISORY.pathname,
                TRAINING.pathname,
              ].includes(Router.pathname)
            ) {
              return {
                ...URLs.GET_QUOTATION,
                pathname: Router.pathname,
              }
            }
          }

          return URLs.GET_QUOTATION
        },
        backgroundColor: COLORS.primary[400],
        color: '#ffffff',
        onClick: (): void => {
          logEvent({
            ga: {
              category: 'CTA',
              action: 'Click Get Quote',
              label: 'Header',
            },
          })
        },
      },
      {
        label: <Icon as={Facebook} boxSize="6" />,
        getLink: (): Link => FACEBOOK,
        backgroundColor: '#3B5999',
        color: '#ffffff',
        onClick: (): void => {
          logEvent({
            ga: {
              category: 'Social',
              action: 'Click Facebook',
            },
          })
        },
      },
      {
        label: <Icon as={FaPhoneAlt} boxSize="6" />,
        divided: true,
        getLink: (): Link => ({
          ...TEL,
          pathname: `tel:${t('contact.tel.number')}`,
        }),
      },
    ],
    [],
  )
}

export type LanguageOption = NavLink & LanguageSelectOption

export const LANGUAGE_SELECT_OPTION: LanguageOption[] = [
  {
    value: 'th',
    label: 'TH',
    divided: true,
    leftIcon: <Icon as={THFlag} w="6" />,
  },
  {
    value: 'en',
    label: 'EN',
    divided: true,
    leftIcon: <Icon as={GBFlag} w="6" />,
  },
  {
    value: 'ja',
    label: 'JP',
    leftIcon: <Icon as={JPFlag} w="6" />,
  },
]

export const NAVIGATION_BAR_HEIGHT = {
  base: '60px',
  md: '72px',
}

export const PROMOTION_TEXT = ''
