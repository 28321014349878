import { useMemo } from 'react'
import { useTranslation } from 'next-i18next'

import { PRIVACY_POLICY, TERMS_OF_USE } from '@/routes'

type Link = {
  label: string
  pathname: string
}

export const useLinks = (): Link[] => {
  const {
    i18n: { language },
  } = useTranslation()

  return useMemo(
    () => [
      {
        label: 'Privacy Policy',
        pathname: `/${language}${PRIVACY_POLICY.pathname}`,
      },
      {
        label: 'Terms of Use',
        pathname: `/${language}${TERMS_OF_USE.pathname}`,
      },
    ],
    [language],
  )
}
