export const HOME: Link = {
  pathname: '/',
  hash: '',
}

export const ABOUT_US: Link = {
  pathname: '/about-us',
  hash: '',
}

export const PRIVACY_POLICY: Link = {
  pathname: '/privacy-policy',
  hash: '',
  isExternal: true,
}

export const TERMS_OF_USE: Link = {
  pathname: '/terms-of-use',
  hash: '',
  isExternal: true,
}

export const BLOGS: Link = {
  pathname: '/blogs',
  hash: '',
}

export const BLOG: Link = {
  pathname: '/blogs/[slug]',
  hash: '',
}

export const BLOGS_BY_TAG: Link = {
  pathname: '/blogs/tags/[id]',
  hash: '',
}

export const OTHER_SERVICES: Link = {
  pathname: '/other-services',
  hash: '',
}
