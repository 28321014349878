export const FACEBOOK: Link = {
  pathname: 'https://www.facebook.com/pdpacore',
  hash: '',
  isExternal: true,
  ariaLabel: 'Facebook',
}

export const PDPA_PRO_PRODUCT: Link = {
  pathname: 'https://pdpa.pro/our-products',
  hash: '',
  isExternal: true,
}

export const LEARN_PDPA: Link = {
  pathname: 'https://learnpdpa.com',
  hash: '',
  isExternal: true,
  ariaLabel: 'LearnPDPA',
}

export const TEL: Link = {
  pathname: 'tel:020245560',
  hash: '',
  ariaLabel: 'tel',
}

export const RISK_ASSESSMENT_TYPEFORM: Link = {
  pathname: 'https://form.typeform.com/to/sAfSVnYR',
  hash: '',
  isExternal: true,
}
