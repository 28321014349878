import { Fragment } from 'react'
import {
  Box,
  Divider,
  Popover,
  PopoverTrigger,
  PopoverBody,
  useDisclosure,
} from '@chakra-ui/react'
import { useSpring, config } from 'react-spring'

import { AnimatedPopoverContent } from '@/lib/springComponent'

import NavigationButton from './NavigationButton'
import { NavLink } from './constants'

export const NAVIGATION_BAR_HEIGHT = {
  base: '60px',
  md: '72px',
}

function MenuDesktopButton({
  subMenus,
  divided,
  ...link
}: NavLink): React.ReactElement {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const spring = useSpring({
    from: {
      opacity: 0,
      transform: 'translate3d(0, 8px, 0)',
    },
    to: {
      opacity: isOpen ? 1 : 0,
      transform: isOpen ? 'translate3d(0, 0px, 0)' : 'translate3d(0, 8px, 0)',
    },
    config: config.stiff,
  })

  return (
    <Fragment>
      <Popover
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        placement="bottom-start"
        trigger="hover"
        isLazy
        lazyBehavior="keepMounted"
        matchWidth
      >
        <PopoverTrigger>
          <Box>
            <NavigationButton {...link} />
          </Box>
        </PopoverTrigger>
        {subMenus && (
          <AnimatedPopoverContent
            style={{
              opacity: spring.opacity,
              transform: spring.transform,
              visibility: spring.opacity.to((opacity) =>
                opacity > 0.1 ? 'visible' : 'hidden',
              ),
            }}
            sx={{
              w: 'fit-content',
              boxShadow: 'none !important',
              border: 'none',
              backgroundColor: 'transparent',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              transition: isOpen ? 'none' : 'visibility 0ms 150ms',
              visibility: isOpen ? 'visible' : 'hidden !important',
            }}
          >
            <PopoverBody
              sx={{
                m: 0,
                p: 0,
                borderRadius: 'md',
                borderColor: 'gray.300',
                backgroundColor: 'white',
                overflow: 'hidden',
                boxShadow: '0 2px 8px 1px #2f2f2f2f',
              }}
            >
              {subMenus &&
                subMenus.map(({ divided, ...menu }, index) => (
                  <Fragment key={`${index}`}>
                    <Box
                      sx={{
                        h: '52px',
                      }}
                    >
                      <NavigationButton
                        {...menu}
                        sx={{
                          '& > *:last-of-type': {
                            justifyContent: 'flex-start',
                          },
                        }}
                      />
                    </Box>
                    {divided && <Divider sx={{ borderColor: 'inherit' }} />}
                  </Fragment>
                ))}
            </PopoverBody>
          </AnimatedPopoverContent>
        )}
      </Popover>
      {divided && <Divider orientation="vertical" />}
    </Fragment>
  )
}

export default MenuDesktopButton
