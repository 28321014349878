import { useEffect } from 'react'
import {
  Flex,
  Box,
  Spacer,
  useDisclosure,
  useTheme,
  Icon,
  Link,
  Text,
  Center,
} from '@chakra-ui/react'
import { useRouter } from 'next/router'

import { ReactComponent as Logo } from '@/svg/logo.svg'

import ScrollLink from '@/lib/scroll'

import { HOME } from '@/routes'

import HamburgerButton from './HamburgerButton'
import LanguageSelect from './LanguageSelect'
import MenuDesktop from './MenuDesktop'
import MenuMobile from './MenuMobile'
import Banner from './Banner'

import { NAVIGATION_BAR_HEIGHT, PROMOTION_TEXT } from './constants'

function NavigationBar({
  isShow = true,
}: {
  isShow?: boolean
}): React.ReactElement {
  const theme = useTheme()
  const { pathname } = useRouter()

  const {
    isOpen: isOpenMenu,
    onClose: onCloseMenu,
    onToggle: onToggleMenu,
  } = useDisclosure()

  useEffect(() => {
    const handleResize = () => {
      if (!isOpenMenu) return

      if (window.innerWidth > parseInt(theme.breakpoints.md)) {
        onCloseMenu()
      }
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [isOpenMenu])

  useEffect(() => {
    const scrollingElement = document.scrollingElement
    if (scrollingElement) {
      if (isOpenMenu) {
        scrollingElement.classList.toggle('overflow-hidden', true)
      } else {
        scrollingElement.classList.toggle('overflow-hidden', false)
      }
    }
  }, [isOpenMenu])

  const { isOpen: isOpenBanner, onOpen: onOpenBanner } = useDisclosure()

  useEffect(() => {
    if (PROMOTION_TEXT) {
      setTimeout(onOpenBanner, 100)
    }
  }, [])

  return (
    <Box
      as="header"
      sx={{
        w: 'full',
        background: 'white',
        h: NAVIGATION_BAR_HEIGHT,
        boxShadow: '0px 1px 0px #E8E8E8',
        zIndex: 'banner',
        position: 'sticky',
        top: 0,
        left: 0,
        transform: 'translate3d(0, 0, 1000px)',
      }}
    >
      <Flex
        as="nav"
        sx={{
          h: 'full',
          w: 'full',
          background: 'inherit',
          alignItems: 'center',
          position: 'relative',
          zIndex: 'docked',
          mx: 'auto',
          maxW: {
            base: 'unset',
            xl: '1500px',
          },
        }}
      >
        <Link
          as={ScrollLink}
          url={{
            ...HOME,
            hash: pathname === HOME.pathname ? 'hero' : '',
          }}
          scrollOption={{
            hashSpy: false,
          }}
          sx={{ h: 'full', cursor: 'pointer' }}
          aria-label="PDPA Core"
        >
          <Center layerStyle="actionable" sx={{ h: 'full' }}>
            <Icon
              as={Logo}
              sx={{
                h: { base: '20px', xs: '26px', md: '32px' },
                w: 'auto',
                px: 4,
              }}
            />
          </Center>
        </Link>

        {isShow && (
          <>
            <Spacer />
            <MenuDesktop />
            <LanguageSelect
              sx={{
                h: NAVIGATION_BAR_HEIGHT,
              }}
            />
            <HamburgerButton
              isOpen={isOpenMenu}
              onClick={onToggleMenu}
              sx={{
                display: {
                  base: 'block',
                  md: 'none',
                },
                w: NAVIGATION_BAR_HEIGHT,
              }}
            />
          </>
        )}
      </Flex>
      {isShow && (
        <>
          <Box
            sx={{
              zIndex: 'docked',
              position: 'absolute',
              top: '100%',
              left: 0,
              w: 'full',
            }}
          >
            <Banner isOpen={isOpenBanner}>
              <Text sx={{ fontWeight: 'medium', color: 'white' }}>
                {PROMOTION_TEXT}
              </Text>
            </Banner>
            <MenuMobile isOpen={isOpenMenu} onClose={onCloseMenu} />
          </Box>
          <Box
            onClick={onCloseMenu}
            sx={{
              visibility: isOpenMenu ? 'visible' : 'hidden',
              zIndex: 0,
              position: 'absolute',
              top: 0,
              left: 0,
              w: 'full',
              h: '100vh',
              bg: '#000000',
              opacity: isOpenMenu ? 0.5 : 0,
              transition: isOpenMenu
                ? '500ms opacity 0ms, 0ms visibility 0ms'
                : '500ms opacity 0ms, 0ms visibility 500ms',
              backdropFilter: 'grayscale(100%)',
            }}
          />
        </>
      )}
    </Box>
  )
}

export default NavigationBar
