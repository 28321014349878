import {
  Box,
  Stack,
  VStack,
  HStack,
  AspectRatio,
  Button,
  Grid,
  Text,
  PopoverContent,
  ListItem,
} from '@chakra-ui/react'
import { animated, config } from 'react-spring'

export const AnimatedBox = animated(Box)
export const AnimatedStack = animated(Stack)
export const AnimatedVStack = animated(VStack)
export const AnimatedHStack = animated(HStack)
export const AnimatedAspectRatio = animated(AspectRatio)
export const AnimatedButton = animated(Button)
export const AnimatedGrid = animated(Grid)
export const AnimatedText = animated(Text)
export const AnimatedPopoverContent = animated(PopoverContent)
export const AnimatedListItem = animated(ListItem)

export const DEFAULT_SPRING_CONFIG = config.default
