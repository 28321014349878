export const IMPLEMENTATION: Link = {
  pathname: '/services/implementation',
  hash: '',
}

export const ADVISORY: Link = {
  pathname: '/services/advisory',
  hash: '',
}

export const TRAINING: Link = {
  pathname: '/services/training',
  hash: '',
}

export const COMPLIANCE: Link = {
  pathname: '/services/compliance',
  hash: '',
}

export const LEGAL: Link = {
  pathname: '/services/legal',
  hash: '',
}
