import { Box, Divider, VStack, Collapse } from '@chakra-ui/react'

import NavigationButton from './NavigationButton'

import { useLinks, NAVIGATION_BAR_HEIGHT } from './constants'

interface Props {
  isOpen?: boolean
  onClose?: () => void
}

function MenuMobile({ isOpen = false, onClose }: Props): React.ReactElement {
  const LINKS = useLinks()

  return (
    <Collapse in={isOpen}>
      <VStack
        spacing="0"
        sx={{
          w: 'full',
          h: 'fit-content',
          alignItems: 'stretch',
          bgColor: 'white',
          overflowY: 'auto',
          maxH: `calc(100vh - ${NAVIGATION_BAR_HEIGHT.base})`,
        }}
      >
        {LINKS.map(({ divided, subMenus, ...link }, index) => {
          return (
            <Box key={`${index}`}>
              <Box sx={{ h: NAVIGATION_BAR_HEIGHT.base }}>
                <NavigationButton
                  {...link}
                  onClick={() => {
                    if (onClose) onClose()
                    if (link.onClick) link.onClick()
                  }}
                />
              </Box>
              {subMenus &&
                subMenus.map(({ ...link }, index) => {
                  return (
                    <Box key={`${index}`} sx={{ h: '44px', fontSize: 'sm' }}>
                      <NavigationButton
                        {...link}
                        onClick={() => {
                          if (onClose) onClose()
                          if (link.onClick) link.onClick()
                        }}
                      />
                    </Box>
                  )
                })}
              {index < LINKS.length - 1 && divided && (
                <Divider orientation="horizontal" />
              )}
            </Box>
          )
        })}
      </VStack>
    </Collapse>
  )
}

export default MenuMobile
