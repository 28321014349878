import { Fragment } from 'react'
import { Flex, Text, Spacer, Link } from '@chakra-ui/react'

import { Link as LinkType } from './types'

interface Props {
  links: LinkType[]
  id?: string
}

function Links({ links, id }: Props): React.ReactElement {
  return (
    <Flex
      id={id}
      layerStyle="container"
      sx={{
        display: {
          base: 'flex',
          md: 'none',
        },
        w: 'full',
        justifyContent: 'center',
        flexFlow: 'row nowrap',
        py: 4,
        fontSize: 'sm',
        color: 'gray.600',
      }}
    >
      {links.map((link, index) => {
        return (
          <Fragment key={link.label}>
            <Link href={link.pathname} isExternal>
              <Text align="center">{link.label}</Text>
            </Link>
            {index !== links.length - 1 && <Spacer sx={{ minW: 5, minH: 2 }} />}
          </Fragment>
        )
      })}
    </Flex>
  )
}

export default Links
